import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';
import styles from './Contact.module.css';

const Contact = (props) => {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      className={`p-5 ${styles.container}`}
      id='contact'
      data-aos='fade-in'
    >
      <Row className='contact row justify-content-center'>
        <Col className={`col-md-5 ${styles.contact_left}`} md>
          <h1 className='pt-3'>{t('contact')}</h1>
          <p>{t('for_every_question_or')}</p>
          <div className='dbox w-100 d-flex align-items-start pt-3'>
            <span className='fa fa-map-marker m-2'></span>
            <p className='m-2'>
              <span>{t('address')}</span> {t('city_street')}
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span className='fa fa-phone m-2'></span>
            <p className='m-2'>
              <span>{t('phone')} </span>
              <a href='tel://123456789'>+381 65 3106868</a>
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span className='fa fa-paper-plane m-2'></span>
            <p className='m-2'>
              <span>Email: </span>
              <a href='mailto:info@yoursite.com'>logistics@transnorth.rs</a>
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span className='fa fa-globe m-2'></span>
            <p className='m-2'>
              <span>Website: </span>
              <a href='/'>www.transnorth.rs</a>
            </p>
          </div>
        </Col>
        <Col className={`col-md-7 ${styles.contact_right}`} md>
          <h1>{t('contact_us')}</h1>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
