import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ReCaptcha.css';

const ReCaptcha = () => {
  function onChange(value) {
    console.log('Captcha value:', value);
  }

  return (
    <div className='rc-anchor' id='captcha'>
      <ReCAPTCHA
        sitekey='6LdbsWUeAAAAAN5cZzwIidQgpSAJxtFI3OXAD4ZE'
        onChange={onChange}
      />
    </div>
  );
};
export default ReCaptcha;
