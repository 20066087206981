import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import logo from '../images/transnorth.png';
import styles from './Footer.module.css';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
      className={`text-center text-lg-start text-muted pt-2 ${styles.bg}`}
    >
      <Container fluid className='text-center mt-5'>
        <Row className='mt-3'>
          <Col md className='col-md-4 col-lg-4 col-xl-3 mx-auto mb-4'>
            <div className={styles.footer_left}>
              <a href='#home'>
                <img
                  className='footer-img mb-2'
                  src={logo}
                  width='250'
                  height='35'
                  alt='Logo'
                />
              </a>
            </div>
            <p>{t('footer_p')}</p>
            <div className='footer-social'>
              <a
                href='https://www.facebook.com/TRANS-NORTH-DOO-111481464807883'
                className='m-2 text-reset'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa fa-facebook-f'></i>
              </a>

              <a
                href='https://www.instagram.com/transnorthdoo/'
                className='m-2 text-reset'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa fa-instagram'></i>
              </a>
            </div>
          </Col>
          <Col md className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
            <h6 className={`fw-bold mb-4 ${styles.service_h6}`}>
              {t('SERVICES')}
            </h6>
            <p>
              <a href='#road' className='text-reset'>
                {t('road_transport')}
              </a>
            </p>
            <p>
              <a href='#ship' className='text-reset'>
                {t('ship_transport')}
              </a>
            </p>
            <p>
              <a href='#avio' className='text-reset'>
                {t('avio_transport')}
              </a>
            </p>
            <p>
              <a href='#train' className='text-reset'>
                {t('train_transport')}
              </a>
            </p>
          </Col>
          <Col md className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
            <h6 className={`fw-bold mb-4 ${styles.service_h6}`}>
              {t('OTHER')}
            </h6>
            <p>
              <a href='#about' className='text-reset'>
                {t('about_us')}
              </a>
            </p>
            <p>
              <a href='#whyUs' className='text-reset'>
                {t('why_us')}
              </a>
            </p>
            <p>
              <a href='#insurance' className='text-reset'>
                {t('insurance')}
              </a>
            </p>
            <p>
              <a href='#services' className='text-reset'>
                {t('services')}
              </a>
            </p>
          </Col>
          <Col md className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
            <h6 className={`fw-bold mb-4 ${styles.service_h6}`}>
              {t('CONTACT')}
            </h6>
            <p>
              <i className='fa fa-home'></i> {t('address')} {t('city_street')}
            </p>
            <p>
              <i className='fa fa-envelope me-3'></i> logistics@transnorth.rs
            </p>
            <p>
              <i className='fa fa-phone me-3'></i> +381 65 3106868
            </p>
            <p>
              <i className='fa fa-print me-3'></i> +381 21 6615288
            </p>
          </Col>
        </Row>
      </Container>
      {/* COPYRIGHT */}
      <div
        className={`text-center p-4 ${styles.service_h6} ${styles.copyright}`}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
      >
        © 2021 Copyright:
        <a
          className={`text-reset fw-bold me-5 ${styles.copyright_a}`}
          href='https://transnorth.rs/'
        >
          transnorth.rs
        </a>
        <span> Developed by: </span>
        <a
          href='https://vladimirvecanski.netlify.app'
          target='_blank'
          rel='noreferrer'
          className={`text-reset fw-bold ${styles.copyright_a}`}
        >
          Vladimir Vecanski
        </a>
      </div>
    </footer>
  );
};

export default Footer;
