import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import plane from '../images/aircraft.jpg';
import train from '../images/freight-train.jpg';
import ship from '../images/ship.jpg';
import truck from '../images/truck.jpg';
import styles from './Home.module.css';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Carousel fade id='home'>
      <Carousel.Item interval={3000}>
        <img className={styles.carousel_img} src={plane} alt='First slide' />
        <Carousel.Caption>
          <h3 className={styles.h3}>{t('avio_transport')}</h3>
          <p className={styles.p}>{t('avio_transport_p')}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styles.carousel_img} src={ship} alt='Second slide' />
        <Carousel.Caption>
          <h3 className={styles.h3}>{t('ship_transport')}</h3>
          <p className={styles.p}>{t('ship_transport_p')}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styles.carousel_img} src={truck} alt='Third slide' />
        <Carousel.Caption>
          <h3 className={styles.h3}>{t('road_transport')}</h3>
          <p className={styles.p}>{t('road_transport_p')}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className={styles.carousel_img} src={train} alt='Third slide' />
        <Carousel.Caption>
          <h3 className={styles.h3}>{t('train_transport')}</h3>
          <p className={styles.p}>{t('train_transport_p')}</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Home;
