import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Map = () => {
  const mapStyles = {
    height: '80vh',
    width: '100%',
  };

  const defaultCenter = {
    lat: 45.283429902276524,
    lng: 19.844247372101727,
  };

  return (
    <LoadScript googleMapsApiKey='AIzaSyD2yrIBajHl0jtbU-iO2ckq2EcmqJsVYoM'>
      <GoogleMap mapContainerStyle={mapStyles} zoom={15} center={defaultCenter}>
        <Marker position={defaultCenter} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
