import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
// import Animation from '../UI/Animation';
import plane from '../images/plane1.jpg';
import ship from '../images/ship2.jpg';
import train from '../images/train1.jpg';
import truck from '../images/truck1.jpg';
import styles from './Services.module.css';

const Services = () => {
  const { t } = useTranslation();

  return (
    // <Animation>
    <Container
      fluid='md'
      className={styles.container}
      id='services'
      data-aos='fade-in'
    >
      {/* ROAD TRANSPORT */}
      <Row className='row m-3 p-3 mt-5' id='#road'>
        <Col lg={true}>
          <img
            src={truck}
            className={styles.img}
            alt='truck'
            id='road'
            data-aos='fade-left'
          />
        </Col>
        <Col lg={true} className='road-infos mt-5 p-3'>
          <h1 className={`text-center ${styles.services_h1}`}>
            {t('service_road_transport')}
          </h1>
          <p className='text-center mt-5'>{t('service_road_transport_p')}</p>
        </Col>
      </Row>
      {/* SHIP TRANSPORT */}
      <Row className='row m-3 p-3 mt-5' id='#'>
        <Col lg={true} className='order-2 mt-5 p-3'>
          <h1 className={`text-center ${styles.services_h1}`}>
            {t('service_ship_transport')}
          </h1>
          <p className='text-center mt-5'>{t('service_ship_transport_p')}</p>
        </Col>
        <Col lg={true} className='order-lg-2'>
          <img
            src={ship}
            className={styles.img}
            alt='cargo ship'
            id='ship'
            data-aos='fade-right'
          />
        </Col>
      </Row>
      {/*  AVIO TRANSPORT */}
      <Row className='row m-3 p-3 mt-5' id='#avio'>
        <Col lg={true}>
          <img
            src={plane}
            className={styles.img}
            alt='airplane'
            id='avio'
            data-aos='fade-left'
          />
        </Col>
        <Col lg={true} className='road-infos mt-5 p-3'>
          <h1 className={`text-center ${styles.services_h1}`}>
            {t('service_аir_transport')}
          </h1>
          <p className='text-center mt-5'>{t('service_аir_transport_p')}</p>
        </Col>
      </Row>
      {/* TRAIN TRANSPORT */}
      <Row className='row m-3 p-3 mt-5' id='#train'>
        <Col lg={true} className='order-2 mt-5 p-3'>
          <h1 className={`text-center ${styles.services_h1}`}>
            {t('service_train_transport')}
          </h1>
          <p className='mt-5 text-center'>{t('service_train_transport_p')}</p>
        </Col>
        <Col lg={true} className='order-lg-2'>
          <img
            src={train}
            className={styles.img}
            alt='cargo train'
            id='train'
            data-aos='fade-left'
          />
        </Col>
      </Row>
    </Container>
    // </Animation>
  );
};

export default Services;
