import { useTranslation } from 'react-i18next';
import styles from './About.module.css';
import {
  FcCurrencyExchange,
  FcGlobe,
  FcInTransit,
  FcAssistant,
} from 'react-icons/fc';
import logistics from '../images/logistics.jpg';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const About = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.about} id='about'>
      <Row className='about' data-aos='zoom-in'>
        <Col className='about-left' md>
          <img
            className={`${styles.about_img} img-fluid`}
            src={logistics}
            alt='logistics'
          />
        </Col>
        <Col className={styles.about_right} md>
          <h1 className={styles.about_h1}>{t('about_us')}</h1>
          <p>{t('about_p1')}</p>
          <p>{t('about_p2')}</p>
          <p className='text-start text-wrap'>
            <span>
              <FcAssistant size={32} className={`mx-3 ${styles.icon}`} />
            </span>
            {t('about_p3')}
          </p>
          <p className='text-start '>
            <span>
              <FcGlobe size={32} className={`mx-3 ${styles.icon}`} />
              {t('about_p4')}
            </span>
          </p>
          <p className='text-start'>
            <span>
              <FcInTransit size={32} className={`mx-3 ${styles.icon}`} />
            </span>
            {t('about_p5')}
          </p>
          <p className='text-start'>
            <span>
              <FcCurrencyExchange size={32} className={`mx-3 ${styles.icon}`} />
            </span>
            {t('about_p6')}
          </p>
          <p>{t('about_p7')}</p>
          <p>{t('about_p8')}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
