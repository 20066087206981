import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Modals = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {t('modal_text')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('modal_text_p')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className='btn'
          style={{ backgroundColor: 'orange' }}
        >
          {t('modal_btn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modals;
