import Navigation from './components/Navigation';
import Home from './components/Home';
import Card from './components/Card';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import WhyWorkWithUs from './components/WhyWorkWithUs';
import Footer from './components/Footer';
import Map from './UI/Map';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({ delay: 200, duration: 3000 });
    AOS.refresh();
  }, []);

  return (
    <div className='App'>
      <Navigation />
      <main>
        <Home />
        <About />
        <WhyWorkWithUs />
        <Card />
        <Services />
        <Contact />
        <Map />
      </main>
      <Footer />
    </div>
  );
};

export default App;
