import plane from '../images/plane.jpg';
import ship from '../images/ship1.jpg';
import train from '../images/train.jpg';
import truck from '../images/trucks.jpg';
import { useTranslation } from 'react-i18next';
import styles from './Card.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Card = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={styles.container}>
      <Row className={`m-5 p-5 ${styles.row}`} data-aos='fade-left'>
        <Col className={`mb-3 ${styles.flip_card}`} sm={12} lg={3}>
          <div className={styles.flip_card_inner}>
            <div className={styles.flip_card_front}>
              <img
                className={`img-fluid ${styles.flip_card_img}`}
                src={truck}
                alt='Avatar'
              />
            </div>
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${styles.flip_card_back}`}
            >
              <h1 className={styles.card_h1}>{t('road_transport')}</h1>
              <p className={`m-3 ${styles.card_p}`}>
                {t('road_transport_p_card')}
              </p>
              <a
                href='#road'
                className={`btn btn-dark mt-3 ${styles.card_btn}`}
              >
                {t('more')}
              </a>
            </div>
          </div>
        </Col>
        <Col className={`mb-3 ${styles.flip_card}`} sm={12} lg={3}>
          <div className={styles.flip_card_inner}>
            <div className={styles.flip_card_front}>
              <img
                className={`d-block img-fluid ${styles.flip_card_img}`}
                src={ship}
                alt='Avatar'
              />
            </div>
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${styles.flip_card_back}`}
            >
              <h1 className={styles.card_h1}>{t('ship_transport')}</h1>
              <p className={`m-3 ${styles.card_p}`}>
                {t('ship_transport_p_card')}
              </p>
              <a
                href='#ship'
                className={`btn btn-dark mt-3 ${styles.card_btn}`}
              >
                {t('more')}
              </a>
            </div>
          </div>
        </Col>
        <Col className={`mb-3 ${styles.flip_card}`} sm={12} lg={3}>
          <div className={styles.flip_card_inner}>
            <div className={styles.flip_card_front}>
              <img
                className={`d-block img-fluid ${styles.flip_card_img}`}
                src={plane}
                alt='Avatar'
              />
            </div>
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${styles.flip_card_back}`}
            >
              <h1 className={styles.card_h1}>{t('avio_transport')}</h1>
              <p className={`m-3 ${styles.card_p}`}>
                {t('avio_transport_p_card')}
              </p>
              <a
                href='#avio'
                className={`btn btn-dark mt-3 ${styles.card_btn}`}
              >
                {t('more')}
              </a>
            </div>
          </div>
        </Col>
        <Col className={`mb-3 ${styles.flip_card}`} sm={12} lg={3}>
          <div className={styles.flip_card_inner}>
            <div className={styles.flip_card_front}>
              <img
                className={`d-block img-fluid ${styles.flip_card_img}`}
                src={train}
                alt='Avatar'
              />
            </div>
            <div
              className={`d-flex flex-column align-items-center justify-content-center ${styles.flip_card_back}`}
            >
              <h1 className={styles.card_h1}>{t('train_transport')}</h1>
              <p className={`m-3 ${styles.card_p}`}>
                {t('train_transport_p_card')}
              </p>
              <a
                href='#train'
                className={`btn btn-dark mt-3 ${styles.card_btn}`}
              >
                {t('more')}
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        data-aos='fade-right'
        className={`m-5 p-5 ${styles.row}`}
        id='insurance'
      >
        <h1 className={`text-center ${styles.card2_h1}`}>{t('insurance')}</h1>
        <p className={`text-center m-3 px-5 ${styles.card2_p}`}>
          {t('insurance_p')}
        </p>
      </Row>
    </Container>
  );
};

export default Card;
