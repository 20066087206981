import { Col, Container, Row } from 'react-bootstrap';
import styles from './WhyWorkWithUs.module.css';
import { useTranslation } from 'react-i18next';

const WhyWorkWithUs = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.bg}>
      <Container
        fluid
        className={`container mt-3 p-2 ${styles.container}`}
        id='whyUs'
        data-aos='fade-up'
      >
        <Row className={`m-3 p-3 ${styles.row}`}>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('competitive')}</h3>
            <p className='text-center'>{t('competitive_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('service')}</h3>
            <p className='text-center'>{t('service_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('dedicated')}</h3>
            <p className='text-center'>{t('dedicated_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('crystal_clear')}</h3>
            <p className='text-center'>{t('crystal_clear_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('personal')}</h3>
            <p className='text-center'>{t('personal_p')}</p>
          </Col>
        </Row>
        <Row className={`m-3 p-3 ${styles.row}`}>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('pro_active')}</h3>
            <p className='text-center'>{t('pro_active_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('preventing')}</h3>
            <p className='text-center'>{t('preventing_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('solution_oriented')}</h3>
            <p className='text-center'>{t('solution_oriented_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('experienced')}</h3>
            <p className='text-center'>{t('experienced_p')}</p>
          </Col>
          <Col className={styles.col} xs={12} md={4} lg xxl>
            <h3 className='mb-3 text-center'>{t('no_nonsense')}</h3>
            <p className='text-center'>{t('no_nonsense_p')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyWorkWithUs;
